
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faArrowUpRightFromSquare as farArrowUpRightFromSquare,
    } from '@fortawesome/pro-regular-svg-icons';
    import {
        faTwitter,
        faYoutube,
        faFacebookF,
        faLinkedinIn,
        faInstagram,
    } from '@fortawesome/free-brands-svg-icons';

    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import Logo from '@/components/Partials/Logo';
    import PoweredByC1 from '@/components/Partials/PoweredByC1';

    dayjs.extend(utc);

    export default {
        name: 'Footer',

        components: {
            FontAwesomeIcon,
            Logo,
            PoweredByC1,
            FeedbackModal: () => import(/* webpackChunkName: "FeedbackModal" */'@/components/Partials/FeedbackModal'),
        },

        data() {
            return {
                icons: {
                    farArrowUpRightFromSquare,
                    faLinkedinIn,
                    faFacebookF,
                    faYoutube,
                    faTwitter,
                    faInstagram,
                },
                showFeedbackModal: false,
            };
        },

        computed: {
            year() {
                return dayjs().format('YYYY');
            },
        },

        methods: {
            handleAction(action) {
                this[action]();
            },
            handleFeedbackClick() {
                this.showFeedbackModal = true;
            },
            closeFeedbackModal() {
                this.showFeedbackModal = false;
            },
        },
    };
